<template>
  <video ref="videoPlayer" class="video-js"></video>
</template>

<script>
import { useStore } from "vuex";
import videojs from "video.js";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  name: "Admin_Tests_VideoViewer",
  data() {
    return {
      player: null,
    };
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
</style>
