<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Tests', route: 'Admin_Tests' },
        { name: 'Update Test Details', route: 'Admin_Tests' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errMsg" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in msg" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent>
            <label>Test Information</label>
            <label for="form.cow_id">Sample ID</label>
            <input type="text" v-model="form.cow_id" class="input" required />

            <label for="form.farmer_name">Client Name</label>
            <input type="text" v-model="form.farmer_name" class="input" />

            <label for="form.vet_practice_name">Vet Practice Name</label>
            <input type="text" v-model="form.vet_practice_name" class="input" />

            <label for="form.animaltype">Animal Type</label>
            <select
              name="form.animaltype"
              id="form.animaltype"
              class="input"
              v-model="form.animaltype"
              required
            >
              <option value="1">Bovine</option>
              <option value="2">Ovine</option>
              <option value="3">Equine</option>
              <option value="4">Caprine</option>
            </select>

            <label for="form.sampletype">Sample Type</label>
            <select
              name="form.sampletype"
              id="form.sampletype"
              class="input"
              v-model="form.sampletype"
              required
            >
              <option value="1">Individual</option>
              <option value="2">Pooled</option>
            </select>

            <label for="form.status">Status</label>
            <select
              name="form.status"
              id="form.status"
              class="input"
              v-model="form.status"
              required
            >
              <option value="green">Negative - Green</option>
              <option value="red">Positive - Red</option>
              <option value="process">PROCESS - Processing Test</option>
              <option value="failed">Repeat Required</option>
            </select>

            <div v-if="form.status === 'failed'">
              <label for="form.failreasons_drop"
                >Select a Failure Reason Template.</label
              >
              <select
                v-on:change="this.setFailReason()"
                name="form.failreasons_drop"
                id="form.failreasons_drop"
                class="input"
                v-model="form.failreasons_drop"
              >
                <option
                  v-for="(row, index) in failreasons"
                  :key="index"
                  :value="row"
                >
                  {{ row }}
                </option>
              </select>
              <label for="form.failreason">Reason for Failure</label>
              <textarea class="input" rows="5" v-model="form.failreason">
              </textarea>
            </div>

            <label for="form.created_at">Created</label>
            <input
              type="datetime-local"
              v-model="form.created_at"
              class="input"
              step="1"
              required
            />

            <label for="form.bucket_file">Bucket File</label>
            <input
              type="text"
              v-model="form.bucket_file"
              class="input"
              readonly
            />
            <label for="form.bucket_file">Test Performed By</label>
            <input
              type="text"
              v-model="form.tester_name"
              class="input"
              readonly
            />            
            <label for="form.original_file">Original File</label>
            <input
              type="text"
              v-model="form.original_file"
              class="input"
              readonly
            />
            <label for="form.request_id">ML Request ID</label>
            <input
              type="number"
              v-model="form.request_id"
              class="input"
              readonly
            />
            <label for="form.notes">Notes / Symptoms</label>
            <textarea
              type="number"
              v-model="form.notes"
              class="input"
              readonly
            />
            <br />
            <br />
            <label>Detected Video File</label>
            <Admin_Tests_VideoViewer
              v-if="this.form.detected_bucket_file !== null"
              :options="this.playerOptions_dect"
            />
            <br />
            <br />
            <label>Original File</label>
            <Admin_Tests_VideoViewer
              v-if="this.form.bucket_file !== null"
              :options="this.playerOptions"
            />
            <br />
            <br />

            <button
              v-if="
                this.form.detected_bucket_file !== null ||
                this.form.detected_bucket_file !== ''
              "
              style="margin-right: 10px"
              class="btn btn-orange"
              value="Download Detected Video"
              v-on:click="
                DownloadVideo(
                  'https://detected-videos.s3.eu-west-1.amazonaws.com',
                  this.form.detected_bucket_file
                )
              "
            >
              Download Detected Video
            </button>
            <button
              v-if="
                this.form.detected_bucket_file !== null ||
                this.form.detected_bucket_file !== ''
              "
              style="margin-right: 10px"
              class="btn btn-orange"
              value="Download Detected Video"
              v-on:click="
                DownloadVideo(
                  'https://bpi-sa-preprod.s3.eu-west-1.amazonaws.com',
                  this.form.bucket_file
                )
              "
            >
              Download Bucket Video
            </button>
          </form>
        </div>
        <div class="col-6">
          <form class="form">
            <label>Test Results</label>
          </form>
          <table>
            <thead>
              <tr>
                <th>Pest</th>
                <th>Reviewer input</th>
                <th>Manual Count</th>
                <th>ML Count</th>
                <th>Status</th>
                <th>Time of Result</th>
                <th>Invalid</th>
                
              </tr>
            </thead>
            <tbody v-if="(form.animaltype ==1 || form.animaltype ==2 || form.animaltype ==4) && form.test_type!=3">
              <tr>
                <td id = "pestForm.Type">Coccidia</td>
                <td> 
                  <input id = "Coccidia_input" type = "number"  class="input" v-model="pestForm.Count_Coccidia" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Coccidia'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Coccidia'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Coccidia' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Coccidia' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Coccidia'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
                <td colspan="1">
                  <input type="checkbox" v-model="isChecked_inconclusive" @change="handleCheckboxinconclusive"/>
                </td>

              </tr>

              <tr>
                <td id = "pestForm.Type">Strongyle</td>
                <td> 
                  <input id = "Strongyle_input" type = "number" class="input" v-model="pestForm.Count_Strongyle" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyle'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyle'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Strongyle' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Strongyle' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Strongyle'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
               
              </tr>
              <tr>
                <td id = "pestForm.Type">Nematodirus</td>
                <td> 
                  <input id = "Nematodirus_input" type = "number" class="input" v-model="pestForm.Count_Nematodirus" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Nematodirus'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Nematodirus'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Nematodirus' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Nematodirus' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Nematodirus'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
              </tr> 
              <tr>
                <td id = "pestForm.Type">Trichuris</td>
                <td> 
                  <input id = "Trichuris_input" type = "number" class="input" v-model="pestForm.Count_Trichuris" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Trichuris'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Trichuris'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Trichuris' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Trichuris' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Trichuris'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr>                     
              <tr>
                <td id = "pestForm.Type">Strongyloides</td>
                <td> 
                  <input id = "Strongyloides_input" type = "number" class="input" v-model="pestForm.Count_Strongyloides" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyloides'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyloides'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Strongyloides' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Strongyloides' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Strongyloides'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
                <td colspan="1">
                  <input type="checkbox" v-model="isChecked_inconclusive_strongyloides" @change="handleCheckboxinconclusive"/>
                </td>

              </tr>      
              <tr>
                <td id = "pestForm.Type">Moniezia</td>
                <td> 
                  <input id = "Moniezia_input" type = "number" class="input" v-model="pestForm.Count_Moniezia" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Moniezia'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Moniezia'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Moniezia' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Moniezia' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Moniezia'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr> 

            </tbody>

            <tbody v-if ="form.animaltype == 3">
              <tr>
                <td>Coccidia</td>
                <td> 
                  <input id = "Coccidia_input" type = "number" class="input" v-model="pestForm.Count_Coccidia" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Coccidia'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Coccidia'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Coccidia' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Coccidia' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Coccidia'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
                <td colspan="1">
                <input type="checkbox" v-model="isChecked_inconclusive" @change="handleCheckboxinconclusive"/>
                </td>

              </tr>

              <tr>
                <td>Strongyle</td>
                <td> 
                  <input id = "Strongyle_input" type = "number" class="input" v-model="pestForm.Count_Strongyle" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyle'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyle'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Strongyle' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Strongyle' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Strongyle'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr>
              <tr>
                <td>Strongyloides</td>
                <td> 
                  <input id = "Strongyloides_input_equine" type = "number" class="input" v-model="pestForm.Count_Strongyloides" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyloides'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Strongyloides'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Strongyloides' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Strongyloides' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Strongyloides'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>
                <td colspan="1">
                  <input type="checkbox" v-model="isChecked_inconclusive_strongyloides" @change="handleCheckboxinconclusive"/>
                </td>

              </tr>      
              <tr>
                <td>Parascaris</td>
                <td> 
                  <input id = "Parascaris_input" type = "number" class="input" v-model="pestForm.Count_Parascaris" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Parascaris'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Parascaris'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Parascaris' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Parascaris' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Parascaris'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr>    
              <tr>
                <td>Anoplocephala</td>
                <td> 
                  <input id = "Anoplocephala_input" type = "number" class="input" v-model="pestForm.Count_Anoplocephala" min="0" step="30" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Anoplocephala'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Anoplocephala'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Anoplocephala' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Anoplocephala' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Anoplocephala'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr> 
                    
            </tbody>

            <tbody v-if="form.test_type == 3">

              <tr>
                <td>Liver Fluke</td>
                <td> 
                  <input id = "LiverFluke_input" type = "number" class="input" v-model="pestForm.Count_LiverFluke" min="0" step="3" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Liver Fluke'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Liver Fluke'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Liver Fluke' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Liver Fluke' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Liver Fluke'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>


              </tr>

              <tr>
                <td>Rumen Fluke</td>
                <td> 
                  <input id = "RumenFluke_input" type = "number" class="input" v-model="pestForm.Count_RumenFluke" min="0" step="3" placeholder="0" >
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Rumen Fluke'">
                    {{ countOutput(pest.pest_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                  <template v-for="pest in pests" :key="pest.id">
                    <template v-if="pest.pest === 'Rumen Fluke'">
                    {{ countOutput(pest.original_count) }}
                    </template>
                  </template> 
                </td>
                <td>
                      <template v-for="pest in pests" :key="pest.id">
                        <template v-if="pest.pest === 'Rumen Fluke' && pest.status == 'negative'">
                          <span class="icon icon-heart" style="color: #04a394"></span>
                        </template>
                        <template v-if="pest.pest === 'Rumen Fluke' && pest.status == 'positive'">
                          <span class="icon icon-heart" style="color: #d50014"></span>
                        </template>                      
                    </template> 
                </td>
                <td>
                    <template v-for="pest in pests" :key="pest.id">
                      <template v-if="pest.pest === 'Rumen Fluke'">
                                {{ TimeParse(pest.created_at) }}
                      </template>
                    </template>  
                </td>

              </tr>


            </tbody>

          </table>
          <br />
          <br />
            <input type="checkbox" v-model="isChecked" @change="handleCheckboxChange"/>
            <label > Copy results from ML ? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;          
            </label>
            
            <!-- <button v-on:click="AddResult_full" class="btn btn-green">Save Results<i class="icon icon-pen"></i>
                  </button> -->
            <br/>
            <br/>
            <br/>
            <label id = "Reviewed_by" for="form.reviewed_by">Reviewed By : </label>
            <input type="text" v-model="form.reviewed_by" class="input" readonly/> 
        
          <br/>
           <br/>

            <label for="form.reviewer_obs">Reviewer Observations :  (Select multiple using 'CTRL +')  </label>
            <br/>
            <br/>
            <select name ="form.reviewer_obs" id = "form.reviewer_obs" class = "input" v-model="form.reviewer_obs" size="8" multiple>
              <option value="All good!">All good!</option>            
              <option value="A lot of bubbles">A lot of bubbles</option>
              <option value="Autofocus">Autofocus</option>
              <option value="Camera movement">Camera movement</option>
              <option value="Dirt on lens">Dirt on lens</option>
              <option value="Faint eggs">Faint eggs</option>
              <option value="Light flickering">Light flickering</option>
              <option value="No image">No image</option>
              <option value="Old slide">Old slide</option>
              <option value="Poor focus">Poor focus</option>
              <option value="Poor slide motion">Poor slide motion</option>
              <option value="Quite dark">Quite dark</option>
              <option value="Slide not fully captured">Slide not fully captured</option>
              <option value="Slide not fully filled">Slide not fully filled</option>
              <option value="Slide stopped moving">Slide stopped moving</option>
              <option value="Too dark">Too dark</option>
              <option value="Too much debris">Too much debris</option>
              <option value="Very large bubble">Very large bubble</option>
              <option value="Water on lens / slide">Water on lens / slide</option>
              <option value="Wrong alignment">Wrong alignment</option>
              <option value="Wrong orientation">Wrong orientation</option>
              </select>

 
            <textarea
              id = "form.reviewer_obs"
              v-model="form.reviewer_obs"
              class="input"
              readonly
              rows="8" cols="20"
            />

                   
          <br/>
          <br/>
          <form class="form" v-on:submit.prevent>
          <label>{{ this.TimeToResults() }}</label>
          </form>
          <br />

          <button v-on:click="AddResult_full" class="btn btn-green">
            Save Results<i class="icon icon-pen"></i>
          </button>
          <br/>
          <br/>
            <!-- <button
              class="btn btn-green"
              style="margin-right: 10px"
              v-on:click="OpenSaveChanges"
            >
              Push Results
            </button> -->


          <button
            v-if="
              pests.length > 0 &&
              this.form.status !== 'failed' &&
              this.form.status !== 'process'
            "
            class="btn btn-green"
            style="margin-right: 10px"
            v-on:click="this.downloadFEC()"
          >
            Download FEC Report
          </button>
        </div>
      </div>
    </div>
    <div id="savechanges-modal" class="modal">
      <div class="modal-content">
        <div class="row">
          <span class="close">&times;</span>
        </div>
        <div class="row">
          <div class="col-12" v-if="this.form.status!=='process' ">
            <div class="error" v-for="error in errMsg" :key="error">
              {{ error }}
            </div>
            <div class="success" v-for="message in msg" :key="message">
              {{ message }}
            </div>
            <p>Are you sure you want to update this test?</p>
            <br />
            <br />
            <input type="checkbox" id="notify" v-model="this.notify" />
            <label class="label" for="notify"
              >Send push notification to the user</label
            >
            <br />
            <br />
            <br />
            <button class="btn btn-green" v-on:click="UpdateTest">
              Save Changes
            </button>
          </div>

          <div class="col-12" v-if="this.form.status =='process' ">

            <p>Please Input and Save the Test Results before pushing !</p>
            <br />

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Navigation from "@/components/Navigation";
import Admin_Tests_VideoViewer from "@/components/Admin.Tests.VideoViewer";
import axios from "axios";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";


export default {
  name: "Admin_Tests_Modify",
  setup() {
    const store = useStore();

    return { store };
  },
  components: {
    Navigation,
    Admin_Tests_VideoViewer,
  },
  computed: {
        FullName() {
      return this.store.state["User"].info.name;
    },
    countOutput() {
      return (value) => {
        if (value === -1) {
          return "Invalid";
        } 
        else if (value === -2){
          return "Counting in-progess";
        }
        else {
          return value;
        }
      };
    },
  },
  data() {
    
    return {
      failreasons: [
        "A large air bubble is present in the slide.\n",
        "Too many small air bubbles.\n",
        "Water on the slide.\n",
        "The full slide was not captured.\n",
        "The full Fluke slide was not captured.\n",
        "No image was detected.\n",
        "Incorrect phone alignment.\n",
        "The sample is too dark for accurate processing affecting egg visibility.\n",
        "Poor focus affecting visibility.\n",
        "Technical issue.\n"
      ],

      pestTypes_bovine: [
        "Coccidia",
        "Strongyle",
        "Nematodirus",
        "Trichuris",
        "Strongyloides",
        "Moniezia",
      ],
      pestTypes_ovine: [
        "Coccidia",
        "Strongyle",
        "Nematodirus",
        "Trichuris",
        "Strongyloides",
        "Moniezia",
      ],
      pestTypes_caprine: [
        "Coccidia",
        "Strongyle",
        "Nematodirus",
        "Trichuris",
        "Strongyloides",
        "Moniezia",
      ],
      pestTypes_equine: [
        "Coccidia",
        "Strongyle",
        "Parascaris",
        "Strongyloides",
        "Anoplocephala",
      ],
      errMsg: "",
      msg: "",
      form: {
        cow_id: "",
        status: "",
        created_at: "",
        bucket_file: null,
        tester_name: null,
        original_file: "1111",
        request_id: "",
        pdf: "",
        farmer_name: "",
        vet_practice_name: "",
        sampletype: 1,
        animaltype: 1,
        test_type:1,
        detected_bucket_file: null,
        notes: "",
        failreason: "",
        failreasons_drop: "",
        fail_comments:"",
        reviewed_by: this.store.state["User"].info.name,
        reviewer_obs:"All good!",
      },
      pestForm: {
        Type: "",
        Count_Coccidia: 0,
        Count_Strongyle: 0,
        Count_Nematodirus: 0,
        Count_Strongyloides: 0,
        Count_Moniezia: 0,
        Count_Anoplocephala: 0,
        Count_Parascaris: 0,
        Count_Trichuris:0,
        Count_LiverFluke:0,
        Count_RumenFluke:0,
        Status: "",
        Count:0,
        Inconclusive: false,
      },
      pests: "",
      playerOptions: {
        height: "280",
        autoplay: false,
        muted: true,
        controls: true,
        language: "en",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [],
      },
      playerOptions_dect: {
        height: "280",
        autoplay: false,
        muted: true,
        controls: true,
        language: "en",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [],
      },

      reviewer_obs_val :[],
      
      notify: true,
      isChecked: false,
      isChecked_inconclusive :false,
      isChecked_inconclusive_strongyloides : false
    };
  },
  methods: {

    handleBeforeUnload(event) {

      if((this.form.status =='process') || (this.form.reviewed_by =='') ){
      // Customize the confirmation message
      const confirmationMessage = "Are you sure you want to leave this page?";
      // Set the confirmation message
      event.returnValue = confirmationMessage;
      return confirmationMessage;
      }
    },

    GetData() {
      this.store
        .dispatch("Admin/GetTestInfo", this.$route.params.id)
        .then((result) => {
          this.form = result.data;
          this.form.created_at = this.form.created_at.slice(0, -5);
          this.pests = result.test_result;
        })
        .catch((err) => {
          this.errMsg = err.messages;
        });
    },
    UpdateTest() {
      // Adding the function to update the reviewer details automatically....
      this.Addreviewer();
      this.AddObs();
   
     // Add a way to update the value of Reviewer observations here ....
      
      
      this.store
        .dispatch("Admin/UpdateTestInfo", {
          id: this.$route.params.id,
          body: { ...this.form, notify: this.notify },
        })
        .then((result) => {
          this.msg = result.messages;
        })
        .catch((err) => {
          this.errMsg = err.messages;
        });
    },
    Addreviewer(){
      const value = this.getReviewedByValue();
      this.form.reviewed_by = value;
    },
    AddObs(){
      const value_obs = this.getObsValue();
      this.form.reviewer_obs = value_obs;
    },
    getReviewedByValue(){
      return this.store.state["User"].info.name;
    }
    ,
    getObsValue(){
      console.log("here from inside the getobs value - ",this.reviewer_obs_val);
      return this.reviewer_obs_val;
    }
    ,
    AddResult_full() {
      if((this.form.status != "failed")){

        console.log("Value of obs val from add result",this.form.reviewer_obs);
        this.reviewer_obs_val = this.form.reviewer_obs;
        console.log("Value of obs val from add result",this.reviewer_obs_val);
        if((this.form.animaltype==3) && (this.form.test_type !=3)){

          this.AddResult_Coccida();
          this.AddResult_Strongyle();
          this.AddResult_Parascaris();
          this.AddResult_Strongyloides();
          this.AddResult_Anoplocephala();
          this.OpenSaveChanges();
          
          
    
        }
        if(((this.form.animaltype==1)||(this.form.animaltype==2)||(this.form.animaltype==4)) && (this.form.test_type !=3)){
        
          this.AddResult_Coccida();
          this.AddResult_Strongyle();
          this.AddResult_Nema();
          this.AddResult_Strongyloides();
          this.AddResult_Moniezia();
          this.AddResult_Trichuris();
          this.OpenSaveChanges();
         
                
          
        }
        if(((this.form.animaltype==1)||(this.form.animaltype==2)||(this.form.animaltype==3)||(this.form.animaltype==4)) && (this.form.test_type==3)){
          
          this.AddResult_Liver();
          this.AddResult_Rumen();
          this.OpenSaveChanges();
        }
    }
      else {
        console.log("Value of obs val from add result",this.form.reviewer_obs);
        this.reviewer_obs_val = this.form.reviewer_obs;
        console.log("Value of obs val from add result",this.reviewer_obs_val);
        this.OpenSaveChanges();
      }

    },
        AddResult_Coccida() {
      this.pestForm.Count = this.pestForm.Count_Coccidia;
      if (this.pestForm.Count>= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Coccidia";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
            // Set focus to the next form element
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
        AddResult_Strongyle() {
      this.pestForm.Count = this.pestForm.Count_Strongyle;
      if (this.pestForm.Count>= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Strongyle";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();

        
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },    
        AddResult_Nema() {
      this.pestForm.Count = this.pestForm.Count_Nematodirus;
      if (this.pestForm.Count >= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Nematodirus";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
        AddResult_Strongyloides() {
      this.pestForm.Count = this.pestForm.Count_Strongyloides;
      if (this.pestForm.Count >= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Strongyloides";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
      AddResult_Moniezia() {
      this.pestForm.Count = this.pestForm.Count_Moniezia;
      if (this.pestForm.Count >= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Moniezia";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
        AddResult_Parascaris() {
      this.pestForm.Count = this.pestForm.Count_Parascaris;
      if (this.pestForm.Count >= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Parascaris";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
        AddResult_Anoplocephala() {
      this.pestForm.Count = this.pestForm.Count_Anoplocephala;
      if (this.pestForm.Count >= -1) {
        
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Anoplocephala";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
          AddResult_Trichuris() {
      this.pestForm.Count = this.pestForm.Count_Trichuris;

      if (this.pestForm.Count >= -1) {
        
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Trichuris";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
    AddResult_Liver() {
      this.pestForm.Count = this.pestForm.Count_LiverFluke;
      if (this.pestForm.Count>= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Liver Fluke";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();

        
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    }
    ,
    AddResult_Rumen() {
      this.pestForm.Count = this.pestForm.Count_RumenFluke;
      if (this.pestForm.Count>= -1) {
        this.pestForm.Status =
          this.pestForm.Count > 0 ? "positive" : "negative";
        this.pestForm.Count =
          this.pestForm.Inconclusive === true ? -1 : this.pestForm.Count;
        this.pestForm.Type = "Rumen Fluke";
        this.store
          .dispatch("Admin/CreateUpdateTestResult", {
            id: this.$route.params.id,
            body: this.pestForm,
          })
          .then((result) => {
            this.msg = result.messages;
            this.GetData();

        
          })
          .catch((err) => {
            this.errMsg = err.messages;
          });
      } else {
        this.errMsg = [];
        if (this.pestForm.Count < 0) {
          this.errMsg.push("Please ensure pest count is 0 or greater.");
        }
        if (this.pestForm.Type == "") {
          this.errMsg.push("Please choose a pest type.");
        }
      }
    },
    handleCheckboxChange() {
      // Checkbox state has changed
      if (this.isChecked) {
      for(let ps=0;ps<this.pests.length;ps++)
      {
        const pest = this.pests[ps];
        if(pest.pest == "Coccidia"){
          this.pestForm.Count_Coccidia = pest.original_count;
        }
        if(pest.pest == "Strongyle"){
          this.pestForm.Count_Strongyle = pest.original_count;
        }
        if(pest.pest == "Nematodirus"){
          this.pestForm.Count_Nematodirus = pest.original_count;
        }
        if(pest.pest == "Strongyloides"){
          this.pestForm.Count_Strongyloides = pest.original_count;
        }
        if(pest.pest == "Moniezia"){
          this.pestForm.Count_Moniezia = pest.original_count;
        }
        if(pest.pest == "Trichuris"){
          this.pestForm.Count_Trichuris = pest.original_count;
        }
        if(pest.pest == "Anoplocephala"){
          this.pestForm.Count_Anoplocephala = pest.original_count;
        }
        if(pest.pest == "Parascaris"){
          this.pestForm.Count_Parascaris = pest.original_count;
        }
        if(pest.pest == "Liver Fluke"){
          this.pestForm.Count_Parascaris = pest.original_count;
        }
        if(pest.pest == "Rumen Fluke"){
          this.pestForm.Count_Parascaris = pest.original_count;
        }

      }
        
        // Perform additional actions here
      } else {
        console.log('Checkbox is unchecked');
        // Perform additional actions here
      }
    },
    handleCheckboxinconclusive(){

      if(this.isChecked_inconclusive){

        // for(let ps=0;ps<this.pests.length;ps++)
        // {
        //   const pest = this.pests[ps];
          // if(pest.pest == "Coccidia"){
            this.pestForm.Count_Coccidia = -1;
          // }
        //}
      }
      if(this.isChecked_inconclusive_strongyloides){

        // for(let ps=0;ps<this.pests.length;ps++)
        // {
        // const pest = this.pests[ps];

        // if(pest.pest == "Strongyloides"){
            this.pestForm.Count_Strongyloides = -1;
        // }

        // }
      }
    }
    ,
    DeleteResult(pestid, index) {
      this.store
        .dispatch("Admin/DeleteTestResult", {
          test_id: this.$route.params.id,
          result_id: pestid,
        })
        .then((result) => {
          this.pests.splice(index, 1);
          this.msg = result.messages;
          this.GetData();
        })
        .catch((err) => {
          this.errMsg = err.messages;
        });
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    TimeToResults() {
      if (this.form.created_at && this.pests !== "" && this.pests.length > 0) {
        let time1 = this.form.created_at.split(".");
        time1 = new Date(time1[0]);
        let time2 = this.pests[0].created_at.split(".");
        time2 = new Date(time2[0]);
        const hours = parseInt(
          Math.abs(time2.getTime() - time1.getTime()) / (1000 * 60 * 60)
        );
        const minutes = parseInt(
          (Math.abs(time2.getTime() - time1.getTime()) / (1000 * 60)) % 60
        );
        const seconds = parseInt(
          (Math.abs(time2.getTime() - time1.getTime()) / 1000) % 60
        );
        return `Test took approximately ${hours} hours, ${minutes} minutes & ${seconds} seconds.`;
      } else {
        return "No results available yet.";
      }
    },

    DownloadVideo(url, uri) {
      axios
        .get(`${url}/${uri}`, {
          responseType: "blob",
          headers: {
            Authorization: "",
          },
        })
        .then(({ data }) => {
          saveAs(data, uri);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    OpenSaveChanges() {
     
      // Get the modal
      var modal = document.getElementById("savechanges-modal");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close")[0];

      // When the user clicks on the button, open the modal
      modal.style.display = "block";

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      };

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
    },
    downloadFEC() {
      this.store
        .dispatch("Admin/DownloadFEC", { id: this.$route.params.id })
        .then((result) => {
          // Begin PDF
          html2pdf(result.html, {
            margin: [0, 8, 15, 0],
            filename: this.form.cow_id + " - FEC.pdf",
            type: "jpeg",
            quality: 100,
            html2canvas: { dpi: 900, letterRendering: true, scale: 3 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          });
        })
        .catch((err) => {
          console.log(err.messages);
        });
    },
    setFailReason() {
      if(this.form.failreasons_drop.includes("A large air bubble is present in the slide.")){
        this.form.failreason = "A large air bubble is present in the slide.\n\nComments: Please ensure the slide is filled fully for accurate analysis. Elevating one end of the slide can help with avoiding air bubbles when filling the slide. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("Too many small air bubbles.")){
        this.form.failreason = "Too many small air bubbles.\n\nComments: Once saline is added to the sample, make sure not to shake the sample to avoid air bubbles. Instead gently invert the vial or flick the end of the vial to mix the sample. Please contact customer support if you have any questions.The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("Water on the slide.")){
        this.form.failreason = "Water on the slide.\n\nComments: Please dry off the slide thoroughly ensuring it is fully dried before inserting into the reader. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("The full slide was not captured.")){
        this.form.failreason = "The full slide was not captured.\n\nComments: The start/end of your slide was not recorded. Due to the missing data an accurate analysis cannot be done. Please try again making sure the 'L' symbol is visible at the start and end of the test. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("The full Fluke slide was not captured.")){
        this.form.failreason = "The full slide was not captured.\n\nComments: The start/end of your slide was not recorded. Due to the missing data an accurate analysis cannot be done. Please try again making sure the '=' symbol is visible at the start and end of the test. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("No image was detected.")){
        this.form.failreason = "No image was detected.\n\nComments: Your phone may need to be re-adjusted on the phone attachment. Please make sure a full, clear round microscopic image is visible before beginning your test. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("Incorrect phone alignment.")){
        this.form.failreason = "Incorrect phone alignment.\n\nComments: This can affect the image quality and prevent the full testing area from being visible for accurate analysis. Please ensure the full circle of the microscopic image is visible to allow for accurate analysis of the sample. Please contact customer support if you have any questions. The credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("The sample is too dark for accurate processing affecting egg visibility.")){
        this.form.failreason = "The sample is too dark for accurate processing affecting egg visibility.\n\nComments: Please allow the slide to rest for 10 minutes before trying again. If the sample is still very dark, please contact customer support. Your credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("Poor focus affecting visibility.")){
        this.form.failreason = "Poor focus affecting visibility.\n\nComments: Please clean all lenses. To clean the internal lens inside the reader please use a long cotton swab and then try again. If this issue persists, please contact customer support. Your credit has been refunded."
      }
      if(this.form.failreasons_drop.includes("Technical issue.")){
        this.form.failreason = "Technical issue.\n\nComments: Please contact customer support if you have any questions. The credit has been refunded."
      }
      
    },

  },
  mounted() {
    this.GetData();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    

  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
   
  },
  watch: {
    form() {
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: `https://bpi-sa-preprod.s3.eu-west-1.amazonaws.com/${encodeURIComponent(
            this.form.bucket_file
          )}`,
        },
      ];
      this.playerOptions_dect.sources = [
        {
          type: "video/mp4",
          src: `https://detected-videos.s3.eu-west-1.amazonaws.com/${encodeURIComponent(
            this.form.detected_bucket_file
          )}`,
        },
      ];
    }
    ,
    pests() {
      this.TimeToResults();
    },
  },
};


</script>

<style>
.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
  margin-bottom: 5px;
}

.success {
  height: auto;
  width: 100%;
  background: #04a394;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #069486;
  margin-bottom: 5px;
}

table .input {
  height: 30px;
  width: 100%;
  font-size: 17px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  background: #fff !important;
  outline: 0;
  border: 1px solid #c8ced5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 1px;
  box-sizing: border-box;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
